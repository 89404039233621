const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://6a2lp539li.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://t29motuioi.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.1',
    HOSTNAME: 'https://company.staging.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://j12xi7i7gd.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;